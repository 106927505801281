<template>
    <page-header-wrapper>
		<template slot="extra">
			<a-button @click="reback" type="primary"><a-icon type="rollback" />返回</a-button>
		</template>
        <a-card :bordered="false">
            <div class="table-page-search-wrapper">
                <div>
                    <div class="title">标题</div>
                    <div class="main"><a-input v-model="form.title" placeholder="请输入标题" /></div>
                </div>
                <!-- <div>
                    <div class="title">分类</div>
                    <div class="main">
                        <a-radio-group name="radioGroup" v-model="form.type" @change="getType">
                            <a-radio :value="item.id" v-for="(item,index) in category" :key="index">
                                {{item.title}}
                            </a-radio>
                        </a-radio-group>
                    </div>
                </div> -->
                <div>
                    <div class="title">文章正文</div>
                    <div class="main">
                        <vue-ueditor-wrap v-model="form.content" :config="myConfig"></vue-ueditor-wrap>
                    </div>
                </div>
            </div>
            <div id="submit">
                <a-button @click="cancel">取消</a-button>
                <a-button type="primary" id="btn" @click="submit">提交</a-button>
            </div>    
        </a-card>
    </page-header-wrapper>
</template>

<script>
    import moment from 'moment'
    import { Modal,message } from 'ant-design-vue'
    import VueUeditorWrap from 'vue-ueditor-wrap'
    import { getUrlKey,UEDITOR_HOME_URL,serverUrl,imgUpload,prefix } from '@/utils' 
    import {
        getklType,
        getklInfo,
        editklInfo
    } from '@/api/article'
    var disabled = true
    export default {
        name: '',
        components: {
            VueUeditorWrap
        },
        data() {
            return {
                form:{
                    id:'',
                    title:'',
                    type:'1',
                    content:'',
					summary:''
                },
                myConfig: {
                    // 编辑器不自动被内容撑高
                    autoHeightEnabled: false,
                    // 初始容器高度
                    initialFrameHeight: 360,
                    // 初始容器宽度
                    initialFrameWidth: '100%',
                    serverUrl: serverUrl,
                    UEDITOR_HOME_URL: UEDITOR_HOME_URL
                },
                category:[],    //分类
            }
        },
        created(){
            this.form.id = getUrlKey('id')||'';
            this.getCategory()
            if(this.form.id){
                this.getInfo();
            }
        },
        methods: {
            getCategory(){  //获取分类
            	let params = {}
            	getklType(params).then(res =>{
            		if(res.code == 200){
            			this.category = res.msg
            		}
            	})
            },
			getType(e){ //选择分类
				this.form.type = e.target.value
			},
            getInfo(){
                let params = {
                    id:this.form.id
                }
                getklInfo(params).then(res =>{
                    if(res.code==200){
                        let data = res.data;
                        this.form.title = data.title;
                        this.form.type = data.type;
                        this.form.content = data.content.replace(/undefined/g, "");
                    }
                })
            },
            submit(){
                let params = {
                    id:this.form.id,
                    content:this.form.content.replace(/undefined/g, ""),
                    title:this.form.title,
                    type:this.form.type,
					summary:this.form.summary
                }
                if(!this.form.title){
                    this.$message.info('请输入标题')
                    return false
                }else if(!this.form.content){
                    this.$message.info('请输入内容')
                    return false
                }
                if(disabled){
                    disabled = false
                    editklInfo(params).then(res =>{
                        if(res.code==200){
                            this.$message.success(this.form.id?'编辑成功':'新增成功')
                            this.reset()
                            setTimeout(_=>{
                                this.$router.go(-1)
                                disabled = true
                            },500)
                        }else{
                            disabled = true
                        }
                    })
                }
                
            },
            cancel(){
                this.reset();
                this.$router.go(-1);
            },
            reset(){
                this.form.id = ''
                this.form.title = ''
                this.form.content = ''
            },
			reback(){
				this.$router.go(-1)
			}
        },
        filters: {
        
        }
    }
</script>


<style scoped>
#submit{display: flex;justify-content: center;margin-top: 20px;}
#btn{margin-left:20px;}
.title{
    height:22px;
    font-size:16px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(51,51,51,1);
    line-height:22px;
    margin-bottom: 20px;
}
.main{
    margin-bottom: 25px;
}
</style>

